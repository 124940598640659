import { PrepressCraftsService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'TakeOrders',
  components: {}
})
export default class TakeOrders extends mixins(DialogMixin) {
  @Prop({
    required: true,
    type: Object
  })
  public takeOrdersObj!: { tips: string; showTip: boolean; save: Function; ids: Array<number> };

  public form: {
    factor: number;
    idList: Array<number>;
    prepressCraftsId: string;
    prepressCraftsName: string;
    prepressCraftsValue: Array<number>;
  } = {
    factor: 0,
    idList: [],
    prepressCraftsId: '',
    prepressCraftsName: '',
    prepressCraftsValue: []
  };

  public resourceFormRules = {
    prepressCraftsValue: [
      {
        required: true,
        validator: (rule: any, value: Array<number>, callback: Function): void => {
          if (value.length === 0) {
            callback(new Error(translation('projectProduct.selectPrepressCrafts')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public prepressCraftsOptions: Array<any> = [];

  public async getPrepressCraftsOptions(): Promise<void> {
    try {
      const res = await PrepressCraftsService.listForComponents({}, { currentPage: 1, showCount: 1000 });
      res.data.forEach(x => {
        this.prepressCraftsOptions.push({ id: x.id, lable: x.name, factor: x.factor });
      });
    } catch (error) {
      messageError(error);
    }
  }

  public dialogOpen(): void {
    this.prepressCraftsOptions.length = 0;
    this.getPrepressCraftsOptions();
    this.title = '接单确认';
    Object.assign(this.form, {
      factor: 0,
      idList: this.takeOrdersObj.ids,
      prepressCraftsId: '',
      prepressCraftsName: '',
      prepressCraftsValue: []
    });
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.form as Form).resetFields();
  }

  public setValue(ids: Array<number>): void {
    const prepressCraftsList = this.prepressCraftsOptions.filter(x => !!ids.includes(x.id));
    console.log(ids, prepressCraftsList);
    this.form.prepressCraftsId = prepressCraftsList.map(x => x.id).join(',');
    this.form.prepressCraftsName = prepressCraftsList.map(x => x.lable).join(',');
    this.form.factor = Math.max(...prepressCraftsList.map(x => x.factor));
  }

  public onSubmit(): void {
    (this.$refs.form as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      this.setValue(this.form.prepressCraftsValue);
      try {
        await this.takeOrdersObj.save({ ...this.form } as any);
        Message.success(translation('operationRes.operationSuccess'));
        this.$emit('operate-success');
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
