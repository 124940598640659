import { render, staticRenderFns } from "./take-orders.vue?vue&type=template&id=c43ce5ec&scoped=true&"
import script from "./take-orders.ts?vue&type=script&lang=ts&"
export * from "./take-orders.ts?vue&type=script&lang=ts&"
import style0 from "./take-orders.scoped.scss?vue&type=style&index=0&id=c43ce5ec&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c43ce5ec",
  null
  
)

export default component.exports