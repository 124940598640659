import { FileService } from '@/api';
import { MinIOFile } from '@/api/minio';
import OsFileUpload, { OsFileUploadOptions } from '@/components/os-file-upload/os-file-upload';
import { DialogMixin } from '@/mixins/dialog';
import { UserModule } from '@/store/modules/user';
import { getFileName, messageError } from '@/utils';
import { ElUploadInternalFileDetail, ElUploadInternalRawFile, HttpRequestOptions } from 'element-ui/types/upload';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class UploadFile extends mixins(DialogMixin) {
  @Prop({ required: false, type: String })
  public uploadTitle!: string;

  /**
   * 允许上传的文件类型
   */
  @Prop({ type: String, required: false }) public acceptFileType!: string;

  /**
   * 允许上传的单个文件最大尺寸 单位MB
   */
  @Prop({ type: Number, required: false, default: undefined }) public acceptFileSize!: number;

  /**
   * 允许上传的文件最大数量
   */
  @Prop({ type: Number, required: false, default: undefined }) public acceptLimit!: number;

  /**
   * 所属的业务模块
   */
  @Prop({ type: String, required: true })
  public uploadModule!: string;

  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {
        beforeUpload: null
      };
    }
  })
  public uploadHooks!: {
    /**
     * 文件上传到minio之前
     */
    beforeUpload: (params: Array<MinIOFile>) => Promise<Array<MinIOFile>>;
  };

  public submitLoading = false;

  public fileUploadOptions: OsFileUploadOptions = {
    autoUpload: false,
    multiple: true,
    limit: this.acceptLimit,
    drag: true,
    action: '',
    accept: this.acceptFileType,
    maxSize: this.acceptFileSize,
    uploadLoading: false,
    fileList: [],
    onRemove: this.removeFile,
    httpRequest: this.customRequest,
    beforeUpload: this.beforeUpload
  };

  private toUploadFiles: Array<MinIOFile> = [];

  /**
   * 由于beforeUpload钩子返回false，会触发onRemove，
   * 而为了限制同名文件上传，beforeUpload可能会返回false，导致上传同名文件时，会删除掉之前已经选择过的文件
   * 所以只能自己加一个属性，控制是否删除文件
   */
  private isRepeatFileName = false;

  private assemblyMinIOFileMissions: Array<Promise<void>> = [];

  public get importPath(): string {
    return `platform/upload/${this.uploadModule}/`;
  }

  public dialogOpen(): void {
    // minioService.init();
  }

  public dialogClosed(): void {
    this.setLoading(false);
    // 重置属性
    (this.$refs.upload as OsFileUpload).clearFiles();
    this.toUploadFiles = [];
    this.isRepeatFileName = false;
  }

  public async customRequest(requestOptions: HttpRequestOptions): Promise<void> {
    this.assemblyMinIOFileMissions.push(this.assemblyMinIOFile(requestOptions));
  }

  public async confirmUpload(isArchive: 0 | 1): Promise<void> {
    try {
      (this.$refs.upload as OsFileUpload).submit();
      await Promise.all(this.assemblyMinIOFileMissions);
      // 调用上传前的钩子，允许此时对要上传的文件做最后一次处理
      if (this.uploadHooks.beforeUpload) {
        this.toUploadFiles = await this.uploadHooks.beforeUpload(this.toUploadFiles);
      }
      if (this.toUploadFiles.length === 0) {
        this.toUploadFiles = [];
        (this.$refs.upload as OsFileUpload).clearFiles();
        return;
      }

      this.setLoading(true);
      const uploadedFiles = await FileService.batchUpload(
        this.toUploadFiles,
        `${this.importPath}${UserModule.account}`
      );

      (this.$refs.upload as OsFileUpload).clearFiles();
      this.$emit('upload-success', uploadedFiles, isArchive);
    } catch (error) {
      if (error) messageError(error);
    } finally {
      this.setLoading(false);
    }
  }

  public setLoading(loading: boolean): void {
    this.fileUploadOptions.uploadLoading = loading;
  }

  /**
   * 删除已选择的文件
   * @param file 要删除的文件
   * @returns
   */
  private removeFile(file: ElUploadInternalFileDetail, fileList: Array<any>): void {
    this.fileUploadOptions.fileList = fileList;
    if (!this.isRepeatFileName) {
      this.toUploadFiles = this.toUploadFiles.filter(x => x.name !== file.name);
      return;
    }
    this.isRepeatFileName = false;
  }

  /**
   * 文件上传前的钩子
   * @param file 文件
   * @returns
   */
  private beforeUpload(file: ElUploadInternalRawFile): boolean | Promise<File | Blob | boolean> {
    const fileNames = this.toUploadFiles?.map(x => getFileName(x.name)) || [];
    const isExist = fileNames.includes(getFileName(file.name));
    this.isRepeatFileName = isExist;
    return !isExist;
  }

  private async assemblyMinIOFile(requestOptions: HttpRequestOptions): Promise<void> {
    try {
      const minioFile: MinIOFile = {
        name: requestOptions.file.name,
        stream: Buffer.from(await requestOptions.file.arrayBuffer()),
        metadata: { 'Content-Type': requestOptions.file.type },
        size: requestOptions.file.size,
        originFile: requestOptions.file
      };

      if (!this.toUploadFiles.map(x => x.name).includes(minioFile.name)) {
        this.toUploadFiles.push(minioFile);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
