import { ArchiveStatusEnum, CloseStatusEnum } from './../../../resource/enum/close-status';
import { Component, Watch } from 'vue-property-decorator';
import { OsQueryPlan, OsTable } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { PrepressTaskList, PrepressTaskListQuery, TakeOrderParam, UploadedFile } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  messageError,
  translation,
  dateFormat,
  downloadFileByBlob,
  isNullOrUndefinedForBaseType,
  messageErrors,
  debounce,
  getFileName
} from '@/utils';
import {
  LayoutStatusEnum,
  PageQueryPlanEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  PrepressStatusEnum,
  PrepressTypeEnum
} from '@/resource/enum';
import { customerService, completeManuscriptTaskService, projectProductService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import PrepressTaskDetails from '../prepress-task-details/prepress-task-details.vue';
import { Message } from 'element-ui';
import Redeploy from '../redeploy/redeploy.vue';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { NormalSelectOptions } from '@/resource/model/common';
import { SortOptions } from '@/api/base';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import UploadFile from './upload-file/upload-file.vue';
import { MinIOFile } from '@/api/minio';
import TakeOrders from '../take-orders/take-orders.vue';
@Component({
  components: { PrepressTaskDetails, Redeploy, ElImageViewer, UploadFile, TakeOrders }
})
export default class CompleteManuscriptTask extends mixins(PagingMixin, CustomColumnMixin) {
  public type = PrepressTypeEnum;

  /**
   * 表格key
   */
  public tableKey = true;

  /**
   * 展示确稿预览文件
   */
  public showLayoutImg = false;

  /**
   * 要预览的图片列表
   */
  public previewLayoutImgList: Array<string> = [];

  /**
   * 预览的图片初始索引
   */
  public initialIndex = 0;

  /**
   * 控制上传确稿图显隐
   */
  public artworkConfirmationVisible = false;
  public tableOption: OsTableOption<PrepressTaskList> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { order: 'ascending', prop: 'requiredDeliveryTime' }
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<PrepressTaskList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'prepress.itemCode',
      minWidth: '200px',
      sortable: 'custom',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'modifyCount',
      label: 'prepress.round',
      minWidth: '100px',
      showOverflowTooltip: true,
      fixed: true
    },

    {
      prop: 'thumbnails',
      label: 'prepress.thumbnails',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'layoutPreview',
      label: 'projectProduct.layoutPreview'
    },
    {
      prop: 'projectName',
      label: 'prepress.projectName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'project.customer',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'prepress.visibleSize',
      showOverflowTooltip: true,
      minWidth: '160px',
      formatter: (row: Object): string => {
        return `${(row as PrepressTaskList).visibleWidth} × ${(row as PrepressTaskList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'prepress.finishSize',
      showOverflowTooltip: true,
      minWidth: '160px',
      formatter: (row: Object): string => {
        return `${(row as PrepressTaskList).finishWidth} × ${(row as PrepressTaskList).finishHeight}`;
      }
    },
    {
      prop: 'topPocketSize',
      label: 'prepress.topPocketSize',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'bottomPocketSize',
      label: 'prepress.bottomPocketSize',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'count',
      label: 'prepressTask.count',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'prepressSubmitCount',
      label: 'projectProduct.prepressSubmitCount',
      showOverflowTooltip: true,
      minWidth: '100px'
    },
    {
      prop: 'screenArea',
      label: 'prepressTask.screenArea',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'screenRate',
      label: 'prepressTask.screenRate',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'prepress.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'customerProductName',
      label: 'prepress.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'prepress.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'prepress.requiredDeliveryTime',
      minWidth: '180px',
      sortable: 'custom',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as PrepressTaskList).requiredDeliveryTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'prepressCrafts',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as PrepressTaskList).prepressCrafts) {
          return '--';
        }
        if ((row as PrepressTaskList).itemType === PrepressTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[(row as PrepressTaskList).prepressCrafts]}`
          );
        }
        return translation(
          `prepressLayoutCrafts.${PrepressLayoutCraftsEnum[(row as PrepressTaskList).prepressCrafts]}`
        );
      }
    },
    {
      prop: 'prepressPicture',
      label: 'prepress.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'prepress.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'prepress.remark',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressStatus',
      label: 'prepress.status',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'archiveStatus',
      label: 'projectProduct.archiveStatus',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return translation(`archiveStatus.${ArchiveStatusEnum[(row as PrepressTaskList).archiveStatus]}`);
      }
    },
    {
      prop: 'closeStatus',
      label: 'projectProduct.closeStatus',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as PrepressTaskList).closeStatus === 1
          ? translation('closeStatus.closed')
          : translation('closeStatus.normal');
      }
    },
    {
      prop: 'makeUserName',
      label: 'prepress.makeUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'makeStateTime',
      label: 'prepress.makeStateTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as PrepressTaskList).makeStateTime);
      }
    },

    {
      prop: 'createUserName',
      label: 'common.createUserName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'project.createTime',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as PrepressTaskList).createTime);
      }
    },
    {
      prop: 'attritionFlag',
      label: 'projectProduct.isConsumeRemake',
      minWidth: '120px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (isNullOrUndefinedForBaseType((row as PrepressTaskList).attritionFlag)) {
          return '--';
        }
        return translation(`common.${(row as PrepressTaskList).attritionFlag === 0 ? 'no' : 'yes'}`);
      }
    },
    {
      prop: 'dutyReason',
      label: 'consumed.consumedReason',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionCode',
      label: 'projectProduct.consumedCode',
      minWidth: '150',
      showOverflowTooltip: true
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'prepressTask.typesettingReceivingOrders',
      operationType: 'confirm',
      icon: 'el-icon-check',
      disabled: true,
      permissionCode: 'design:task:receivingDesignOrder',
      handleClick: this.takeOrders
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'prepressTask.finishProductReceivingOrders',
      operationType: 'confirm',
      icon: 'el-icon-check',
      disabled: true,
      permissionCode: 'design:task:receivingFinishOrder',
      handleClick: this.finishProductReceivingOrders
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      plain: true,
      permissionCode: 'design:task:export',
      handleClick: this.export
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<PrepressTaskListQuery>> = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'prepress.itemCode',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'projectName',
      label: 'prepress.projectName',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'prepress.platformProduct',
      option: {
        placeholder: 'consumed.inputPlatformProduct'
      }
    },
    {
      type: 'Input',
      field: 'prepressPicture',
      label: 'prepress.prepressPicture',
      option: {
        placeholder: 'prepress.inputPrepressPicture'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'requiredDeliveryTime',
      label: 'prepressTask.deliveryTime',
      option: {
        rangeSeparator: '~'
      }
    },
    {
      type: 'Select',
      field: 'urgentOrderFlag',
      label: 'order.urgentOrder',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          },
          {
            label: translation('common.no'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'prepressStatusList',
      label: 'prepressTask.billStatus',
      option: {
        placeholder: 'prepressTask.selectBillStatus',
        multiple: true,
        clearable: true
      },
      optionData: (): NormalSelectOptions => {
        return projectProductService.getPrepressStatus().filter(x => x.value !== PrepressStatusEnum.new);
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'project.customer',
      option: {
        placeholder: 'project.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'project.orderCreator',
      option: {
        placeholder: 'project.selectCreator'
      }
    },
    {
      type: 'DateRangePicker',
      field: 'orderCreateTime',
      label: 'order.createDate',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    },
    {
      type: 'Input',
      field: 'visibleWidth',
      label: 'projectProduct.visibleWidth',
      option: {
        placeholder: 'customerPoint.inputVisibleWidth'
      }
    },
    {
      type: 'Input',
      field: 'visibleHeight',
      label: 'projectProduct.visibleHeight',
      option: {
        placeholder: 'customerPoint.inputVisibleHeight'
      }
    },
    {
      type: 'Input',
      field: 'finishWidth',
      label: 'projectProduct.finishWidth',
      option: {
        placeholder: 'customerPoint.inputFinishWidth'
      }
    },
    {
      type: 'Input',
      field: 'finishHeight',
      label: 'projectProduct.finishHeight',
      option: {
        placeholder: 'customerPoint.inputFinishHeight'
      }
    },
    {
      type: 'Select',
      field: 'isOverdue',
      label: 'projectProduct.isOverdue',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.overdue'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'closeStatus',
      label: 'projectProduct.closeStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('closeStatus.normal'),
            value: 0
          },
          {
            label: translation('closeStatus.closed'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'archiveStatusList',
      label: 'projectProduct.archiveStatus',
      option: {
        placeholder: 'common.selectStatus',
        multiple: true
      },
      optionData: projectProductService.getArchiveStatus().filter(x => x.value !== ArchiveStatusEnum.new)
    }
  ];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.completeManuscriptTask;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  /**
   * 控制详情dialog显隐
   */
  public detailsVisible = false;

  /**
   * 控制接单dialog
   */
  public takeOrdersVisible = false;

  public tips: string = '';
  public takeOrdersObj: {
    tips: string;
    showTip: boolean;
    save: Function;
    ids: Array<number>;
  } = {
    tips: '',
    showTip: false,
    ids: [],
    save: this.takeOrder
  };

  /**
   * 印前单据主键
   */
  public prepressId: number | null = null;

  public selectedRows: Array<PrepressTaskList> = [];

  public uploadFiles: Array<{ id: number; itemCode: string }> = [];

  public redeployVisible = false;

  public redeployLoading = false;

  public selectedIds: Array<number> = [];

  /**
   * 展示确稿预览文件
   */
  public showThumbnails = false;

  /**
   * 要预览的图片列表
   */
  public previewImgList: Array<string> = [];

  public queryForm: Partial<PrepressTaskListQuery> = {};

  private defaultQueryForm: Partial<PrepressTaskListQuery> = {
    createUserName: '',
    customerId: undefined,
    finishHeight: undefined,
    finishWidth: undefined,
    itemCode: '',
    orderCreateTime: [],
    platformProductName: '',
    prepressPicture: '',
    prepressStatusList: [],
    projectName: '',
    requiredDeliveryTime: [],
    urgentOrderFlag: undefined,
    visibleHeight: undefined,
    visibleWidth: undefined,
    isOverdue: undefined,
    closeStatus: undefined,
    archiveStatusList: []
  };

  private sortOptions: SortOptions<PrepressTaskList> = this.tableOption.defaultSort!;

  public get area(): string {
    return this.selectedRows
      .reduce((res, curr) => res + (curr.finishWidth / 1000) * (curr.finishHeight / 1000) * curr.count, 0)
      .toFixed(3);
  }
  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }
  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'complete-manuscript-task');
    this.getCustomers();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.finalizedTable as OsTable).clearSelection();
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }

  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<PrepressTaskList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public linkToDetails(project: PrepressTaskList): void {
    this.detailsVisible = true;
    this.prepressId = project.id;
  }

  public handleSelectionChange(selectedData: Array<PrepressTaskList>): void {
    this.selectedRows = selectedData;
  }

  public getStatusClass(task: PrepressTaskList): string {
    switch (task.prepressStatus) {
      case PrepressStatusEnum.waitForProduction:
        return 'danger-dot';
      case PrepressStatusEnum.inProduction:
        return 'primary-dot';
      case PrepressStatusEnum.waitForConfirm:
        return 'warning-dot';
      case PrepressStatusEnum.waitComplete:
        return 'warning-dot';
      default:
        return 'info-dot';
    }
  }

  public getStatusName(task: PrepressTaskList): string {
    if (!task.prepressStatus) {
      return translation('common.unKnownStatus');
    }
    return `prepressStatus.${PrepressStatusEnum[task.prepressStatus]}`;
  }
  /**
   * 转派
   */
  public openRedeployDialog(): void {
    this.selectedIds = this.selectedRows
      .filter(x => x.prepressStatus !== PrepressStatusEnum.waitForProduction)
      .map(x => x.id);
    if (this.selectedIds.length === 0) {
      Message.warning(translation('prepressTask.unMatchedRedeployStatus'));
      return;
    }
    this.redeployVisible = true;
  }

  /**
   * 排版接单
   */
  public takeOrders(): void {
    const ids = this.selectedRows
      .filter(
        x =>
          [PrepressStatusEnum.waitForProduction, PrepressStatusEnum.inProduction].includes(x.prepressStatus) &&
          x.closeStatus === CloseStatusEnum.normal
      )
      .map(x => x.id);
    if (ids.length === 0) {
      Message.warning(translation('prepressTask.unMatchedWaitProduction'));
      return;
    }
    const inProductionIds = this.selectedRows.filter(
      x => [PrepressStatusEnum.inProduction].includes(x.prepressStatus) && x.closeStatus === CloseStatusEnum.normal
    );
    const inProductionTip = inProductionIds.map(x => x.itemCode).join('、');
    if (inProductionIds.length > 0) {
      this.takeOrdersObj.tips = `单据${inProductionTip}已处于制作中，是否确认继续接单？`;
      this.takeOrdersObj.showTip = true;
    } else {
      this.takeOrdersObj.tips = '';
      this.takeOrdersObj.showTip = false;
    }
    this.takeOrdersObj.ids = ids;
    this.takeOrdersObj.save = this.takeOrder;
    this.takeOrdersVisible = true;
  }
  /**
   *
   * @param parames
   * @returns
   */
  public takeOrder(params: TakeOrderParam): Promise<void> {
    return new Promise((resolve, reject) => {
      completeManuscriptTaskService
        .receivingDesignOrder(params)
        .then(() => {
          resolve();
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  /**
   * 成品接单
   */
  public finishProductReceivingOrders(): void {
    const ids = this.selectedRows
      .filter(
        x =>
          [PrepressStatusEnum.notMake, PrepressStatusEnum.waitComplete].includes(x.prepressStatus) &&
          [ArchiveStatusEnum.waitForProduction, ArchiveStatusEnum.inProduction].includes(x.archiveStatus) &&
          x.closeStatus === CloseStatusEnum.normal
      )
      .map(x => x.id);
    if (ids.length === 0) {
      Message.warning(translation('prepressTask.finishProductReceivingTip'));
      return;
    }
    const inProductionIds = this.selectedRows.filter(
      x => [ArchiveStatusEnum.inProduction].includes(x.archiveStatus) && x.closeStatus === CloseStatusEnum.normal
    );
    const inProductionTip = inProductionIds.map(x => x.itemCode).join('、');

    if (inProductionIds.length > 0) {
      this.takeOrdersObj.tips = `单据${inProductionTip}已处于制作中，是否确认继续接单？`;
      this.takeOrdersObj.showTip = true;
    } else {
      this.takeOrdersObj.tips = '';
      this.takeOrdersObj.showTip = false;
    }
    this.takeOrdersObj.ids = ids;
    this.takeOrdersObj.save = this.finishProductReceivingOrder;
    this.takeOrdersVisible = true;
  }

  public finishProductReceivingOrder(params: TakeOrderParam): Promise<void> {
    return new Promise((resolve, reject) => {
      completeManuscriptTaskService
        .receivingFinishOrder(params)
        .then(() => {
          resolve();
          Message.success(translation('operationRes.operationSuccess'));
          this.reloadData();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /**
   * 归档
   */
  public archiveComplete(): void {
    if (
      this.selectedRows.find(
        x => x.prepressStatus !== PrepressStatusEnum.waitComplete || x.closeStatus !== CloseStatusEnum.normal
      )
    ) {
      Message.warning(translation('prepressTask.archiveCompleteTip'));
      return;
    }
    const ids = this.selectedRows.map(x => x.id!);
    try {
      this.tableOption.loading = true;
      completeManuscriptTaskService
        .archiveComplete(ids)
        .then(() => {
          Message.success(translation('operationRes.operationSuccess'));
          this.reloadData();
        })
        .catch(error => {
          messageError(error);
        })
        .finally(() => {
          this.tableOption.loading = false;
        });
    } catch (error) {
      messageErrors(error);
    }
  }

  /**
   * 确稿图片预览
   * @param row 数据行
   * @returns
   */
  public async thumbnailsPreview(row: PrepressTaskList): Promise<void> {
    try {
      // 使用findIndex查找对象
      const index = this.tableOption.data.findIndex(item => item.id === row.id);
      this.initialIndex = index;
      this.showThumbnails = true;
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 上传确稿图
   */
  public openUploadProduceImage(): void {
    this.artworkConfirmationVisible = true;
  }

  /**
   * 完稿（提交审稿）文上传文件之前检查文件名是否符合业务约定的格式
   * @param toUploadfiles 待上传的文件
   */
  public async beforeUploadConfirm(toUploadfiles: Array<MinIOFile>): Promise<Array<MinIOFile>> {
    const matchedFiles: Array<String> = [];
    const unMatchedFileNames: Array<string> = [];
    const repeatFileNames: Array<string> = [];
    this.uploadFiles = [];
    for (const file of toUploadfiles) {
      if (!/-/.test(this.effectiveFileName(file.name))) {
        unMatchedFileNames.push(file.name);
        continue;
      }
      // 去除重复文件
      if (matchedFiles.includes(this.effectiveFileName(file.name))) {
        repeatFileNames.push(file.name);
        continue;
      }
      matchedFiles.push(this.effectiveFileName(file.name));
    }
    // 如果项次出现重复文件
    if (repeatFileNames.length > 0) {
      this.$notify({
        title: translation('tip.tipInfo'),
        dangerouslyUseHTMLString: true,
        message: `${repeatFileNames.join('</br>')}</br>${translation('prepressTask.fileAdded')}`,
        type: 'warning'
      });
      return Promise.reject();
    }

    // 文件全部未通过验证，直接取消
    if (matchedFiles.length === 0) {
      Message.warning(translation('prepressTask.noFileToUpload'));
      return Promise.resolve([]);
    }

    // 校验文件
    const allow = await this.checkConfirmItem(toUploadfiles);
    if (allow) {
      return Promise.resolve(toUploadfiles);
    } else {
      return Promise.reject([]);
    }
  }

  /**
   * 完稿-上传确稿图
   * @param paths 路径
   * @returns
   */
  public artworkConfirmation(files: Array<UploadedFile>, archiveStatus: 0 | 1): void {
    const fileParams: Array<{ id: number; fileId: number; archiveStatus?: number }> = this.processingFilePath(
      files.map(x => {
        return { fileName: x.name, id: x.id }!;
      })
    );
    if (fileParams.length === 0) {
      Message.warning(translation('prepressTask.noFileToUpload'));
      (this.$refs.artworkComplete as UploadFile).setLoading(false);
      return;
    }
    // 是否归档赋值
    fileParams.forEach(item => {
      item.archiveStatus = archiveStatus;
    });

    completeManuscriptTaskService
      .submitConfirmFile(fileParams)
      .then(() => {
        this.artworkConfirmationVisible = false;
        this.reloadData();
        Message.success(translation('dialog.uploadSuccess'));
      })
      .catch(error => {
        (this.$refs.artworkConfirmation as UploadFile).setLoading(false);
        messageError(error);
      });
  }

  public async checkConfirmItem(toUploadfiles: Array<MinIOFile>): Promise<boolean> {
    const fileNamelist: Array<string> = [];
    for (const file of toUploadfiles) {
      fileNamelist.push('v3PO' + this.effectiveFileName(file.name));
    }
    return new Promise((resolve, reject) => {
      completeManuscriptTaskService
        .checkConfirmItem(fileNamelist)
        .then(res => {
          this.uploadFiles = res.data;
          resolve(true);
        })
        .catch(error => {
          messageErrors(error);
          reject(false);
        });
    });
  }

  /**
   * 预览当前行layout
   * @param row 当前预览产品
   */
  public previewLayout(row: PrepressTaskList): void {
    const index = this.previewLayoutImgList.findIndex(x => x === row.layoutPreview);
    this.initialIndex = index;
    this.showLayoutImg = true;
  }

  /**
   * 加载layout链接数据
   */
  public loadLayout(): void {
    const list = this.tableOption.data.filter(x => x.layoutStatus === LayoutStatusEnum.generated).map(x => x.id!);
    if(list.length === 0) {
      this.previewLayoutImgList = [];
      return;
    }
    projectProductService
      .previewLayout(list)
      .then(res => {
        if (!res || res.length === 0) {
          return;
        }

        const urlList: string[] = [];
        this.tableOption.data.forEach(item => {
          const layout = res.find(x => x.id === item.id);
          if(layout) {
            item.layoutPreview = layout.url;
            urlList.push(layout.url);
          }
        });

        this.previewLayoutImgList = [];
        this.previewLayoutImgList = urlList;

        this.tableKey = !this.tableKey;
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 获取Layout状态国际化结果
   * @param data 表格行数据对象
   * @returns 国际化后字符串
   */
  public getLayoutStatus(data: PrepressTaskList): string {
    return translation(`layoutStatusEnum.${LayoutStatusEnum[data.layoutStatus]}`);
  }

  private processingFilePath(files: Array<{ id: number; fileName: string }>): Array<{ id: number; fileId: number }> {
    const fileRes: Array<{ id: number; fileId: number }> = [];
    for (const file of files) {
      // 按照约定，上传到minio的文件名会使用 时间戳_文件名 这种方式拼接
      const itemCode = `v3PO${this.effectiveFileName(file.fileName)}`;
      const prepressTask = this.uploadFiles.find(x => x.itemCode === itemCode)!;

      fileRes.push({
        id: prepressTask.id,
        fileId: file.id
      });
    }
    return fileRes;
  }

  /**
   * 有效文件名为文件名的前15位，该逻辑为业务约定
   * @param file
   * @returns
   */
  private effectiveFileName(filePath: string): string {
    return getFileName(filePath).slice(0, 15);
  }

  private loadData(): void {
    this.tableOption.loading = true;
    completeManuscriptTaskService
      .getList(this.queryForm, this.paging, this.sortOptions)
      .then(res => {
        this.tableOption.data = res.data.map(x => {
          x.screenArea = ((x.finishWidth / 1000) * (x.finishHeight / 1000)).toFixed(6);
          x.screenRate = (x.visibleWidth / x.visibleHeight).toFixed(3);
          return x;
        });
        this.totalData = res.total;

        this.previewImgList = [];
        this.tableOption.data.forEach(item => {
          this.previewImgList.push(item.thumbnails || 'https://api.minio.primarychina.com.cn/public/no_image.jpg');
        });

        // 加载layout数据
        this.loadLayout();
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const ids = this.selectedRows.map(x => x.id);
      const blob = await completeManuscriptTaskService.export(this.queryForm, ids);
      downloadFileByBlob(
        `${translation('prepressTask.exportCompleteManuscriptask')}_${dateFormat(new Date())}.xlsx`,
        blob
      );
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.companyName, value: x.id };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  @Watch('queryForm.orderCreateTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.requiredDeliveryTime')
  private handleRequiredDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredDeliveryStartTime = undefined;
      this.queryForm.requiredDeliveryEndTime = undefined;
      return;
    }
    if (value.length === 2) {
      this.queryForm.requiredDeliveryStartTime = dateFormat(value[0]);
      this.queryForm.requiredDeliveryEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<PrepressTaskList>): void {
    this.operationOptions.forEach(x => {
      if (!['submitConfirmFile', 'export'].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }
}
